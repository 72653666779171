.cursorPointer {
  cursor: pointer;
}

.cursorGrab {
  cursor: grab;
}

.cursorDefault {
  cursor: default;
}
